@import "../styles/colors";

.products-container {
  padding: 0 250px;
  background-color: $products-bg;
}

.products-spinner-container {
  position: relative;
  background-color: $products-bg;
  width: 100%;
  height: calc(100vh - 100px);
}

.delivery-input-container {
  padding: 5px 120px;
}

.next-page-loader {
  width:100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
