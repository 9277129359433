@import "../../styles/colors";

.offers-modal {
  top: 0 !important;
}

.todays-offers-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: $product-details-title;
  margin-right: 20px;
}
