
.custom-navbar {
  height: 46px;
  display: flex;
  align-items: center;
}

.header-content {
  max-width: 1000px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
