@import "../../styles/colors";

.limit-height {
    height: 300px !important;
    padding: 0 !important;
}

.row {
    display: flex;
    align-items: center;
}

.btn-circle {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    text-align: center;
    background-color: $white;
    border: 1px solid $silver;
    color: $black;
}

.img-responsive {
    display: inline-block;
}

.home-logo {
    width: 100px;
    height: 100px;
}

.min-width-100px {
    min-width: 110px;
    flex-shrink: 0;
}

.light-blue {
    color: $icon-blue;
}

.icon {
    position: absolute;
    z-index: 9999;
    top: 10px;
    left: 15px;
    width: 25px;
    height: 25px;
}

.suggestions-container {
    z-index: 99999;
    background: $white;
    left: 0;
    right: 0;
}

.suggestion {
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.suggestion:hover {
    background: $light-silver;
}

.suggestion-icon {
    color: $icon-red;
    font-size: 1.2rem;
    margin-left: 6px;
    margin-right: 20px;
}

.suggestion-icon-right {
    color: $grey;
    margin-right: 10px;
    font-size: 1.2rem;
}

.btn-circle.distance {
    height: 70px;
    width: 70px;
}

.form-control.transparent-border {
    border: 1px solid transparent;
}

.initial-suggestions {
    top: 43px;
    left: 0;
    right: 0;
    z-index: 99999;
    background: $white;
    border: 1px solid $silver;
}

.custom-form-control.form-control {
    height: calc(1.8em + .75rem + 2px) !important;
}
