@import "../../styles/colors";

.logo {
  margin-right: 80px;
}

.nav-link.active {
  color: $nav-link-active !important;
}

.border-bottom-2 {
  border-bottom: 1px solid $silver-border;
}

.header-logo.navbar-brand {
  padding: 0 !important;
}

.navbar-logo {
  width: 100px;
  height: 35px;
}

.z-index-99990 {
  z-index: 99999;
}

.user-info.dropdown-menu {
  left: unset;
  right: 10px;
  border: none;
  box-shadow: 0 0 5px 1px $rating-delivery-color;
  border-top: 2px solid $add-payment-method-color;
  border-radius: 1px;
}

.dropdown-arrow-up {
  width: 12px;
  height: 12px;
  top: -7px;
  left: 48%;
  transform: rotate(135deg);
  background-color: $white;
  border: 0.1rem solid $add-payment-method-color;
  border-right-width: 0;
  border-top-width: 0;
  position: absolute;
}

.custom-navbar.navbar {
  padding: 0.1rem 1rem !important;
}

.auth-color {
  color: $auth-color
}

.cart-count {
  font-size: 1.1rem !important;
  display: flex;
  align-items: center;
}

.header-right {
  width: 300px;
  display: flex;
  justify-content: flex-end;
}

.cart-img {
  width: 28px;
  height: 28px;
  margin-left: 5px;
}

.nav-img {
  width: 17px;
  height: 17px;
}

.nav-img-lg {
  width: 28px;
  height: 28px;
}

.nav-button {
  background-color: $white;
  border: none;

  &:hover {
    background-color: $white;
    border: none;
  }
}

.rewards-btn {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rewards-count-label {
  position: absolute;
  font-size: 0.8rem;
  background-color: $qty-add-btn-color;
  border-radius: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  right: -9px;
  top: 2px;
}

.offers-count-label {
  position: absolute;
  font-size: 0.8rem;
  background-color: $button-blue;
  border-radius: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  right: -3px;
  top: 3px;
}
