@import "../../styles/colors";

.footer-container {
  background: $auth-color;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  border-top: 1px solid $silver;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 260px 10px 260px;
  color: $white;
  flex-direction: column;

  a:not(.social-img-holder), span {
    width: 100%;
    text-align: start;
    color: $white !important;
  }

  a {
    margin-bottom: 5px;
  }
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.footer-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-store-img {
  width: 155px;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.social-img-holder {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.social-img {
  width: 100%;
  height: 100%;
}
