.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  align-items: center;
  justify-content: center;
}
