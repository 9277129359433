@import "./styles/colors";

body {
  background: $white;
  padding-bottom: 15px;
  padding-top: 44px;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.padding-bottom-7px {
  padding-bottom: 7px;
}

.bg-grey {
  background: $grey;
}

.bg-orange {
  background-color: $orange !important;
}

.font-size-lg {
  font-size: 1.6rem !important;
}

.font-size-1-4rem {
  font-size: 1.4rem !important;
}

.font-size-1-2rem {
  font-size: 1.2rem !important;
}

.font-size-1-1rem {
  font-size: 1.1rem !important;
}

.font-size-2 {
  font-size: 0.8rem !important;
}

.font-size-3 {
  font-size: 0.9rem;
}

.font-size-3-lg {
  font-size: 0.95rem;
}

.font-size-5 {
  font-size: 0.6rem !important;
}

.font-size-6 {
  font-size: 0.7rem !important;
}

.font-size-1rem {
  font-size: 1rem !important;
}

.w-30 {
  width: 30% !important;
}

.w-60 {
  width: 60% !important;
}

.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80%;
}

.w-15 {
  width: 15%;
}

.w-500px {
  width: 500px !important;
}

.w-400px {
  width: 400px !important;
}

.min-width-400px {
  min-width: 400px !important;
}

.width-320px {
  width: 320px !important;
}

.width-170px {
  width: 170px !important;
}

.link {
  text-decoration: underline;
  color: $link-color;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.color-silver {
  color: $silver !important;
}

.color-light-blue {
  color: $nav-link-active !important;
}

.color-blue {
  color: $button-blue !important;
}

.color-white {
  color: white !important;
}

.border-full {
  border: 1px solid $silver-border;
}

.background-light-silver {
  background-color: $light-silver;
}

.overflow-y-auto {
  overflow-y: auto;
}

.d-inherit {
  display: inherit;
}

.box-sizing-border-box {
  box-sizing: border-box;
}

.error {
  text-align: left;
  padding-left: 10px;
  color: $red !important;
}

.hidden {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

.close-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: $red;
  color: $white;
}

.bg-green-light {
  background-color: $light-green !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.hover-grey:hover {
  background: $silver-hover !important;
  cursor: pointer;
}

.input-label {
  font-size: 0.65rem !important;
  top: 3px;
  left: 50px;
  color: $silver;
}

.p-t-20px {
  padding-top: 20px !important;
}

.text-purple {
  color: $purple;
}

.border-grey {
  border: 1px solid $grey !important;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $silver;
  flex-shrink: 0;
}

.divider-dashed {
  width: 100%;
  border-top: 1px dashed $silver;
}

.text-dark-grey {
  color: $dark-grey;
}

.bg-light-silver {
  background-color: $light-silver;
}

.width-120px {
  width: 120px;
}

.width-150px {
  width: 150px;
}

.bg-white {
  background-color: $white !important;
}

.text-black {
  color: $black !important;
}

.background-black {
  background-color: $black !important;
}

.border-radius-5px {
  border-radius: 5px !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.btn-small {
  width: 70px;
}

.btn-small-50px {
  width: 50px !important;
}

.margin-bottom-10px {
  margin-bottom: 10px !important;
}

.w-30 {
  width: 30% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-20 {
  width: 20% !important;
}

.w-85 {
  width: 85% !important;
}

.light-silver-bg {
  background-color: $light-silver;
}

.h-100px {
  height: 100px !important;
}

.yellow-star {
  color: $rating-start-color !important;
}

.green-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $light-green;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: 4px;
}

.w-50px {
  width: 50px !important;
}

.w-40px {
  width: 40px !important;
}

.w-90 {
  width: 90% !important;
}

.no-outline {
  outline: none !important;
}

.remove-scroll {
  overflow-y: hidden !important;
}

.scroll-y-auto {
  overflow-y: auto !important;
}

.shops-group {
  background-color: $light-silver;
  border: 1px solid $category-border;
  border-radius: 0;
}

.width-200px {
  width: 200px !important;
}

.width-300px {
  width: 300px !important;
}

.max-width-500px {
  max-width: 500px !important;
}

.height-20px {
  height: 20px;
}

.w-60px {
  width: 60px !important;
}

.height-1000px {
  height: 1000px;
}

.max-width-1000px {
  max-width: 1000px;
}

.max-width-800px {
  max-width: 800px;
}

.static-page-bg {
  background-color: $product-price-box !important;
}

.line-height-initial {
  line-height: initial !important;
}

.silver-text {
  color: $silver-text !important;
}

.ml-230px {
  margin-left: 230px;
}

.color-black {
  color: black !important;
}

.color-red {
  color: $red !important;
}

.height-25px {
  height: 25px !important;
}

.height-300px {
  height: 300px;
}

.border-color-9e9e9e {
  border-color: $checkout-btn-bg-empty-cart;
}

.font-underline {
  text-decoration: underline;
}
