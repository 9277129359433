
.dialog-content {
  padding: 5px;

  div {
    span {
      font-style: italic;
    }
  }
}
