
.invalid-field {
  width: 100%;
  text-align: left;
  padding-left: 10px;
}

.address-type {
  width: calc(100% - 50px);
}
