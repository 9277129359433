@import "../../styles/colors";

.fixed-footer-container {
  position: fixed;
  background: $light-silver;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  border-top: 1px solid $silver;
}

.powered-by-label {
  position: absolute;
  right: 10px;
  font-size: 0.8rem;
};

.h1-hidden {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute!important;
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  clip: rect(1px,1px,1px,1px);
}
