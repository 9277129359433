@import "../../styles/colors";

.padding-left-28px {
  padding-left: 28px;
}

.loading-container {
  width: 100%;
  height: 150px;
}

.vb-product-qty {
  width: calc(100% - 25px);
}
