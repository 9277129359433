@import "../../styles/colors";

.width-10 {
  width: 9.3rem;
}

.rounded-10 {
  border-radius: 10px;
}

.card.shop-item {
  border: 0.04rem solid transparent !important;
}

.shop-hover:hover {
  opacity: 0.7;
}

.max-wh-9rem {
  max-height: 9rem !important;
  max-width: 9rem !important;
}

.wh-8rem {
  width: 8rem !important;
  height: 8rem !important;
}

.img-spinner-container {
  background-color: $light-silver;
}

.rating {
  right: 0.3rem;
  bottom: 0.3rem;
  background-color: $white;
  padding: 0 0.7rem;
  border-radius: 20px;
  border: 1px solid $category-border;
  font-size: 0.9rem;

  .rating-icon {
    color: $rating-start-color;
  }
}

.shop-count {
  top: -5px;
  right: -5px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background-color: $red;
  position: absolute;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-offer {
  position: absolute;
  left: .2rem;
  bottom: .2rem;
  width: 50px;
  height: 50px;
}

.shop-offer-img {
  width: 55px;
  height: 55px;
}

.shop-offer-value {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.78rem;
  padding-top: 3px;
  line-height: 1;
  font-family: serif;
  font-weight: bold;
  margin-left: 2px;
  transform: rotate(-15deg);
}

.rupee {
  margin-right: 1px;
}

.mb-1px {
  margin-bottom: 1px;
}

.pl-5px {
  padding-left: 5px;
}
