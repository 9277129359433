@import "../../styles/colors";

.img-spinner-container {
  background-color: $light-silver;
}

.wh-8rem {
  width: 8rem !important;
  height: 8rem !important;
}

.w-70px {
  width: 70px !important;
}


.leftSidebarImgSpinner {
  height: 100px !important;
  width: 100px !important;
}

.image-loader {
  background-color: silver;
}

.rounded-10px {
  border-radius: 10px !important;
}

.cart-image-loader {
  height: 75px !important;
  width: 70px !important;
}

.image-bg-1 {
  background-color: $loader-bg-1;
}

.image-bg-2 {
  background-color: $loader-bg-2;
}

.image-bg-3 {
  background-color: $loader-bg-3;
}

.image-bg-4 {
  background-color: $loader-bg-4;
}

.image-bg-5 {
  background-color: $loader-bg-5;
}

.wh100px {
  width: 100px !important;
  height: 100px !important;
}

.wh80px {
  width: 80px !important;
  height: 80px !important;
}

.wh70px {
  width: 70px !important;
  height: 70px !important;
}

.br-15px {
  border-radius: 15px;
}

.adWh {
  width: 80%;
  max-width: 650px;
  height: 150px;
  margin-top: 20px;
}

.wh130px {
  width: 130px;
  height: 130px;
}
