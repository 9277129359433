$white: #ffffff;
$black: #000000;
$silver: #C0C0C0;
$grey: #808080;
$dark-grey: #1e2128;
$light-silver: #F5F5F5;
$light-blue: #cef9ff;
$orange: #F09230;
$orange-dark: #d2802a;
$orange-light: #ef6c00;
$lightest-orange: #ffe0b2;
$red: #dc3545;
$light-green: #6adc54;
$silver-border: #e0e0e0;
$silver-border-1: #d7d8d3;
$silver-border-2: #e5e5e5;
$silver-hover: #d5d5d5;
$purple:  #6f42c1;
$yellow: #dbd647;
$light-yellow: #f6ff41;
$silver-text: #616161;
$products-bg: #ebedf1;
$category-border: #ecebeb;
$button-blue: #1d9bf6;
$subscribe-btn-bg: #039be5;
$send-support-btn: #0275d8;
$support-blue-input-border: #8bd8fb;
$subscribe-btn-border: #0288d1;
$checkout-btn-bg-empty-cart: #9e9e9e;
$checkout-btn-bg-non-empty-cart: #06a10b;
$cart-item-text : #424242;
$qty-add-btn-color: #2ac33c;
$qty-minus-btn-color: #f44336;
$offer-color: #ffeb3b;
$menu-color: #fbe2e1;
$menu-color-children: #ffe6e9;
$menu-border-right: #f92f28;
$auth-color: #12131f;
$rating-delivery-color: #bdbdbd;
$product-details-title: #444444;
$product-price-box: #fafafa;
$product-price-box-text-color: #212121;
$offer-price-bg: #e1f5fe;
$offer-price-border: #b3e5fc;
$product-labels-color: #121212;
$delivery-warning-bg: #fcf8e3;
$delivery-warning-border-color: #faebcc;
$delivery-warning-color: #838148;
$payment-green-btn: #2ac33c;
$payment-btn-border: #06a10b;
$shop-name-color: #120846;
$checkout-close-color: #444444;
$promo-silver: #757575;
$payment-menu-bg: #e8edf5;
$credit-card-border-color: #e4e5e7;
$add-payment-method-color: #297bff;
$rating-start-color: #fcc201;
$order-status-color: #7220fe;
$track-order-blue: #01579b;
$replacement-bg: #fbfbfb;
$delete-address-btn-border: #e90033;

$icon-blue: #6abdd6;
$icon-red: #AB150C;
$nav-link-active: #1f7fb1;
$modal-shadow: #ccc;
$modal-border: #eee;
$link-color: #0056b3;
$drag-color: #17a2b8;
$shop-rating-start-color: #ffa726;
$orange-border: #ff9800;
$checkout-dialog-bg: #edf0f4;
$menu-container-bg: #ebedf5;

$loader-bg-1: #FED8D7;
$loader-bg-2: #FFF3E0;
$loader-bg-3: #E1F5FE;
$loader-bg-4: #E1F8D9;
$loader-bg-5: #FFF8EC;
