@import "../styles/colors";

.payment-page-container {
  width: 100%;
  padding: 0 210px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.saved-card {
  border: 1px solid $credit-card-border-color;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 335px;
  height: 75px;
}

.card-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.default-card-icon {
  width: 52px;
  display: flex;
  justify-content: center;
}


.delete-btn {
  font-size: 1.2rem;
}

.card-number {
  color: $shop-name-color;
}

.expiry-date {
  color: $silver-text;
}

.selected-card {
  color: $qty-add-btn-color;
}

.non-selected-card {
  color: $silver-border;

  &:hover {
    color: $grey;
  }
}

.saved-cards-container, .wallets-container {
  display: flex;
  flex-wrap: wrap;
}

.delete-btn {
  color: $qty-minus-btn-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
  }
}

.wallet {
  width: 335px;
  height: 75px;
  border: 1px solid $credit-card-border-color;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px
}

.wallet-img {
  width: 52px;
  height: 52px;;
}

.add-payment-method-btn {
  color: $add-payment-method-color;
  background-color: $white;

  &:hover {
    background-color: $white;
    color: $add-payment-method-color;
  }
}

.saved-upi {
  border: 1px solid $credit-card-border-color;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 335px;
  height: 75px;
}

.upi-pay-img {
  width: 54px;
  height: 20px;
}
